import React, { Component } from 'react'
import Web3 from 'web3'
import { getContractInfo, formatBalanceEther } from './tools.js'
import './App.css'
import { P5GenArt1 } from './P5GenArt1.jsx'

var gSubscription = undefined;

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ethereum: undefined,
      contractInfo: undefined,
      web3: undefined,
      currentBlockNumber: 'N/A',
      contractBalance: 'N/A',
      currentAccount: 'N/A',
      currentBalance: 'N/A',
      mytokens: [] }
  }
  
  componentDidMount() {
    this.loadBlockchainData()
  }
  
  componentWillUnmount() {
    if(undefined!==gSubscription) { gSubscription.unsubscribe() }
  }
  
  isMetaMaskInstalled = () => { return Boolean(window.ethereum && window.ethereum.isMetaMask) };

  loadBlockchainData = async () => {
    let contractInfo = getContractInfo('rinkeby');

    let web3 = new Web3(window.ethereum);
    
    window.ethereum.on('accountsChanged', (accounts) => { window.location.reload() });
    window.ethereum.on('chainChanged', (chainId) => { window.location.reload() });
    
    let contract = undefined;
    let code = await web3.eth.getCode(contractInfo.address)
    if(code!=='0x') {
      contract = new web3.eth.Contract(JSON.parse(contractInfo.abi), contractInfo.address)
      gSubscription = web3.eth.subscribe('newBlockHeaders')
        .on("connected", ()=>{ this.updateBlockchainData() })
        .on("data", this.updateBlockchainData)
        .on("error", ()=>{ console.error("newBlockHeaders subscription failed")} );
    } else {
      console.log("unable to create the contract")
    };
    
    this.setState({ ethereum: window.ethereum, web3, contract })
  }
  
  updateBlockchainData = async (blockHeader=undefined) => {
    const { web3, contract } = this.state;
    
    if(!contract) { return }
    

    let data = { mytokens: [] };
    data.contractBalance = formatBalanceEther(await web3.eth.getBalance(contract._address));

    if(undefined!==blockHeader) { data.currentBlockNumber = blockHeader.number }
      
    let accounts = await web3.eth.getAccounts();
    if(accounts&&accounts.length>0) {
      data.currentAccount = accounts[0];
      let ntokens = await contract.methods.balanceOf(data.currentAccount).call();
      
      for(let tokenidx=0; tokenidx<ntokens; tokenidx++) {
        let id = await contract.methods.tokenOfOwnerByIndex(data.currentAccount, tokenidx).call();
        let tokenuri = await contract.methods.tokenURI(id).call();
        let tokenage = tokenuri.replace('https://ddc.addbrainz.org/tokens/', '').split('/')[1];
        data.mytokens.push({id, tokenuri, tokenage});
      }
      
      data.mytokens.sort((i1, i2)=>{
        if(i1.id>i2.id) { return 1}
        else if (i1.id<i2.id) { return -1}
        else { return 0}
      })

      data.currentBalance = formatBalanceEther(await web3.eth.getBalance(data.currentAccount));
    } else {
      data.currentAccount = "N/A"
      data.currentBalance = "N/A"
    }

    console.log("update state with %o", data)
    this.setState(()=>data);
  }

  connectBlockchain = async e => {
    e.preventDefault();
    await window.ethereum.enable();
  }
  
  createNFT = () => {
    const { web3, contract, currentAccount } = this.state;

    let value = web3.utils.toWei('0.1', 'ether');
    contract.methods.create().send({from: currentAccount, value });
  }
  
  transferNFT = (tokenId) => e => {
    const { web3, contract, currentAccount } = this.state;
    
    let transferto = prompt("Enter the address that will receive the token");
    if(null!==transferto && ""!==transferto) {
      let value = web3.utils.toWei('0.05', 'ether');
      contract.methods.reviveandtransfer(currentAccount, transferto, tokenId).send({from: currentAccount, value });
    }
  }
  
  reviveNFT = (tokenId) => e => {
    const { web3, contract, currentAccount } = this.state;
    
    let value = web3.utils.toWei('0.05', 'ether');
    contract.methods.reviveandtransfer(currentAccount, currentAccount, tokenId).send({from: currentAccount, value });
  }

  renderConnect() {
    return (<button style={{margin: '0.5rem'}} onClick={this.connectBlockchain}>Enable Ethereum</button>)
  }
  
  renderCreate() {
    return (<button style={{margin: '0.5rem'}} onClick={this.createNFT}>Create NFT</button>)
  }
  
  renderToken = (tokendata) => {
    const { contract, web3 } = this.state
    const seed = web3.utils.sha3(web3.utils.sha3(contract._address+tokendata.id));
    console.log("got seed %s", seed);
    
    return (
      <div key={'div-'+tokendata.id} style={{width:'600px', margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div key={'mtk-'+tokendata.id} style={{textAlign: 'center'}}>
          <h2><a target="_blank" href={tokendata.tokenuri} rel="noreferrer">token #{tokendata.id} [age: {tokendata.tokenage} blocks]</a></h2>
        </div>
        { <P5GenArt1 age={tokendata.tokenage} seed={seed} style={{border: '2px solid black'}}/>  }
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
          <button key="b2" style={{margin: '0.5rem'}} onClick={this.reviveNFT(tokendata.id)}>Revive NFT</button>
          <button key="b3" style={{margin: '0.5rem'}} onClick={this.transferNFT(tokendata.id)}>Transfer NFT</button>
        </div>
      </div>
    )
  }
  
  renderTokens = () => {
    const { mytokens } = this.state;
    
    let output = [<hr key='hr-1' />, <h2 key='h2-1'>My NFTs</h2>]
    
    if(mytokens.length===0) {
      output.push(<h5>You don't own any NFTs yet!</h5>)
    }
    
    mytokens.forEach((tokendata,idx)=>{
      output.push(this.renderToken(tokendata))
    })
    
    return output;
  }
  
  render() {
    if(!this.isMetaMaskInstalled()) {
      return (<h1>Please install metamask and refresh this page!</h1>)
    }

    const { contract, currentAccount, currentBlockNumber, currentBalance, contractBalance } = this.state;
    
    if (typeof window.ethereum === 'undefined'||currentAccount==="N/A") {
      return(this.renderConnect());
    }
    
    return (
      <div className="container">
        <h1>DIGITAL DECAY</h1>
        <p key="p1">Current block: { currentBlockNumber }</p>
        <p key="p2">Your account: { currentAccount }</p>
        <p key="p3">Your balance: { currentBalance } Ether</p>
        <p key="p4">The contract: { contract ? contract._address : 'N/A' }</p>
        <p key="p5">Contract balance: { contractBalance } Ether</p>
        { this.renderCreate() }
        { this.renderTokens() }
      </div>
    );
  }
}

export default App;