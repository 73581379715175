
const rinkebyContractJSON = require('./contracts/rinkeby/ERC721DigitalDecay.json');
const ganacheContractJSON = require('./contracts/development/ERC721DigitalDecay.json');

const decodeContractBuildJSON = (json) => {
  try {
    const networkname = Object.keys(json.networks)[Object.keys(json.networks).length-1];
    
    if(networkname===undefined) {
      // console.error("this contract has never been deployed!")
      return false
    }
    
    let contract = {
      address: json.networks[networkname].address,
      abi: JSON.stringify(json.abi)
    }
    
    return contract;
  } catch(ex) {
    console.error("decodeContractBuildJSON - error: %s", ex.message)
    return false;
  }
}

export const getContractInfo = (network) => {
  let info=false
  switch(network) {
    case 'ganache':
      info = decodeContractBuildJSON(ganacheContractJSON);
      break;
    case 'rinkeby':
      info = decodeContractBuildJSON(rinkebyContractJSON);
      break;
    default:
      console.warn('no contract build information available for network %s', network);
  }

  if(false===info) {
    console.error("this contract has never been deployed!")
    info = { address: '', abi: ''}
  }

  return info;
}

export const loadContract = async (web3, contract, contractJSON) => {
  try {
    if(contract.address==='') {
      console.log("loadContract - No contract address given")
      return {
        result: false,
        message: 'No address given',
        contract: false
      };
    }

    let code = await web3.eth.getCode(contract.address)
    if(code==='0x') {
      console.log("loadContract - No contract at given address")
      return {
        result: false,
        message: 'No contract at given address',
        contract: false
      };
    }
    
    if(contract.address!==contractJSON.address) {
      console.log("loadContract - contract address does not match JSON configuration (%s/%s)", contract.address, contractJSON.address)
      return {
        result: false,
        message: 'Contract address does not match JSON configuration',
        contract: false
      };
    }
    
    return {
      result: true,
      message: '',
      contract: new web3.eth.Contract(JSON.parse(contract.abi), contract.address)
    };
  } catch(ex) {
    console.error("blokko-contracts.loadContract - error %s", ex.message);
  }
}

export const formatBalanceEther = (balanceWei) => {
  return Math.round(balanceWei / 10**18 * 1000)/1000;
}

