import React from "react";
import P5Sketch from "./P5Sketch.jsx";

const MAX_HEIGHT = 500;
const MAX_WIDTH = 500;
const STEP = 16;
//
const VARIANCE_FACTOR = 100;
// // const TONES_1 = [[238, 66, 102], [31, 64, 104], [242, 228, 181]];
// // const TONES_2 = [[37, 106, 220], [31, 64, 104], [169, 251, 215]];
const MONOCHROME = [[0, 32, 63]];
//

const MAXAGE=35;

export class P5GenArt1 extends React.Component {
  constructor(props) {
    super(props);
    
    this.lines = [];
    this.offsets = [];
  }
    
  setup = (p5, canvasParentRef) => {
    const { seed } = this.props;

    p5.createCanvas(MAX_HEIGHT, MAX_WIDTH).parent(canvasParentRef);
    p5.stroke(169, 251, 215);
    p5.strokeWeight(2);
    p5.noLoop();
    
    const seedrandom = require('seedrandom');
    let rng = seedrandom(seed);

    for(let i = STEP; i < MAX_HEIGHT - STEP; i += STEP){
      let line = [];
      this.offsets.push({x: 0, y: i})
      for(let j = STEP; j <= MAX_HEIGHT - STEP; j += STEP){
        let distanceToCenter = Math.abs(j - MAX_HEIGHT / 2);
        let variance = Math.max(MAX_HEIGHT / 2 - VARIANCE_FACTOR - distanceToCenter, 0);
        let random = rng() * variance / 2 * -1;
        line.push({x: j, y: random})
      }
      this.lines.push(line);
    }

    p5.redraw()
  };
  
	draw = (p5) => {

    let age = this.props.age;
    let factor = age>=MAXAGE ? 0 : Math.abs(-1/MAXAGE * age + 1);

    console.log("P5GenArt1 draw %s/%s", age, factor)
    
    //draw
    p5.clear()
    for(let i = 5; i < this.lines.length; i++) {
      p5.beginShape();
      for(let j = 0; j < this.lines[i].length; j+=2){
        let attenuatedy = this.offsets[i].y + this.lines[i][j].y * factor
        p5.curveVertex(this.lines[i][j].x, attenuatedy);
        let random_index = Math.floor(Math.random() * MONOCHROME.length);
        const [r, g, b] = MONOCHROME[random_index];
        p5.fill(r, g, b);
        p5.curveVertex(this.lines[i][j+1].x, attenuatedy)
      }
      p5.endShape();
    }
  }
  
  render = () => {
	   return <P5Sketch setup={this.setup} draw={this.draw} />;
  }
};